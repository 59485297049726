import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Signin.css";
import signup1 from "../icons/signup1.png";
import GoogleForm from "./GoogleForm";
import { useAuth } from "../../context/userContext";

const Signin = () => {
  const { setUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState("");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const navigate = useNavigate();

  const handleSignin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/api/v1/auth/login", {
        email,
        password
      });

      if (response.status === 200) {
        setUser(response.data);
        navigate("/");
      } else {
        setNotification("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setNotification("An error occurred while logging in. Please try again.");
    }

    if (isUserLoggedIn) {
      navigate("/dashboard/AddProduct");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="signin-page-container">
      <div className="signin-page-section">
        <div className="signin-section">
          <img src={signup1} alt="signup" className="signin-image" />
        </div>
        <div className="signin-form-section">
          <div className="signin-form-title">
            <h4>GFOKK</h4>
          </div>
          <div className="welcome-back">
            <h2>Welcome Back</h2>
          </div>
          <p className="signin-form-para">
            Not registered yet?<a href="/signup">Sign Up</a>
          </p>
          <form onSubmit={handleSignin}>
            <div className="signin-form-label">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {notification && (
              <div className="signin-notification">{notification}</div>
            )}
            <div className="signup-button">
              <button type="submit">Sign In</button>
              <p className="separator">OR</p>
              <GoogleForm />
            </div>
          </form>
          <p className="signin-para">
            By Signing up, I agree to the
            <span className="underline-text">Terms of Service</span> and
            <span className="underline-text">Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;

// import React, { useState } from "react";
// import "./Signin.css";
// import signup1 from "../icons/signup1.png";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios"; // Import axios for making HTTP requests
// import GoogleForm from "./GoogleForm";

// const Signin = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [notification, setNotification] = useState("");
//   const navigate = useNavigate();

//   const handleSignin = async (event) => {
//     event.preventDefault();

//     try {
//       // Send login request to backend
//       const response = await axios.post("/api/v1/auth/login", {
//         email,
//         password
//       });

//       // Check if login was successful
//       if (response.status === 200) {
//         // Redirect to dashboard or any other page upon successful login
//         navigate("/");
//       } else {
//         setNotification("Login failed. Please check your credentials.");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       setNotification("An error occurred while logging in. Please try again.");
//     }
//   };

//   return (
//     <div className="signin-page-container">

//       <div className="signin-page-section">
//         <div className="signin-section">
//           <img src={signup1} alt="signup" className="signin-image" />
//         </div>
//         <div className="signin-form-section">
//           <div className="signin-form-title">
//             <h4>GFOKK</h4>
//           </div>
//           <div className="welcome-back">
//             <h2>Welcome Back</h2>
//           </div>
//           <p className="signup-form-para">
//             Not registered yet?<Link to="/signup">Sign Up</Link>
//           </p>
//           <form onSubmit={handleSignin}>
//             <div className="signin-form-label">
//               <input
//                 type="email"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <input
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             {notification && (
//                 <div className="signin-notification">{notification}</div>
//               )}
//             <div className="signup-button">
//               <button type="submit" onClick={handleSignin}>
//                 Sign In
//               </button>

//               <p className="separator">OR</p>
//               <GoogleForm />

//             </div>
//           </form>
//           <p className="signin-para">
//             By Signing up, I agree to the
//             <span className="underline-text">Terms of Service</span> and
//             <span className="underline-text">Privacy Policy</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signin;
