import React, { useEffect, useState } from 'react';
import './viewproduct.css';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import warn from './icons/warn.svg'


function ConfirmationDialog({ onCancel, onConfirm }) {
    return (
        <div className="confirmation-dialog">
            <div className='card'>
                <img src={warn} alt='warn img'/>

            <h5>You are about to delete a product</h5>
            <p>Are you sure you want to delete?</p>
            <div className='confirmation-btns'>
                <button onClick={onCancel} className='cncl-btn'>Cancel</button>
                <button onClick={onConfirm} className='del-btn'>Delete</button>
            </div>
            </div>
        </div>
    );
}

function ViewProduct() {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState({
      images: [],
  });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (!isDataFetched) {
            fetchData();
        }
    }, [isDataFetched]);

    useEffect(() => {
   
      const id = setInterval(() => {
          setCurrentIndex((currentIndex + 1) % product.images.length);
      }, 3000);

      setIntervalId(id);

      return () => {

          clearInterval(intervalId);
      };
  }, [currentIndex, product.images.length]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/getproduct/${productId}`);
            const productData = response.data;
            setProduct(productData);
            setIsDataFetched(true);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const handleDelete = async () => {
        try {
          await axios.delete(`/deleteproduct/${productId}`);
            console.log("successfully deleted")
            navigate(-1);
        } catch (error) {
          console.error('Error deleting product:', error);
        }
      };

    const goBack = () => {
        navigate(-1);
    };

    const handleClick = () => {
        clearInterval(intervalId);
        setCurrentIndex((currentIndex + 1) % product.images.length);
    };

    const showConfirmationDialog = () => {
        setShowConfirmation(true);
    };

    const hideConfirmationDialog = () => {
        setShowConfirmation(false);
    };
console.log(product)
    return (
        <div className='product-container'>
            <div className='head_bar'>
                <button className='blue-btn' onClick={goBack}><LuChevronLeft /> Back</button>

                <div className='action-btns'>
                    <Link to={`/dashboard/editproduct/${product._id}`}><div className='edit-btn'><MdOutlineEdit size={18}/></div></Link>
                    <div className='delete-btn' onClick={showConfirmationDialog}><RiDeleteBin6Line size={18} /></div>

                </div>
            </div>

            <div className='product_body'>
                <div className='main-container'>
                  <div className='product_holder'>
                    <div className='view_product'>
                        <div className='view_top'>
                            <p>Product Images</p>
                            <div className='image-container'>
                                {product.images && product.images.length > 0 && (
                                    product.images.map((imageArray, index) => (
                                        <img
                                            key={index} // Add a unique key for each image
                                            src={`http://localhost:8000/uploads/${imageArray}`} // Access the first image URL in the inner array
                                            alt={`Product Img ${index}`}
                                            onClick={handleClick} // Call handleClick when image is clicked
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                        <div className='view_bottom'>
                            <div className='left'>
                                    <span className='info-line'><p className='line-label'>Product Name:</p> <p className='line-data'>{product.name}</p> </span>
                                    <span className='info-line'><p className='line-label'>Category:</p> <p className='line-data'>{product.category}</p> </span>
                                    <span className='info-line'><p className='line-label'>Price:</p> <p className='line-data'>{product.price}/-</p> </span>
                                    <span className='info-line'><p className='line-label'>Type:</p> <p className='line-data'>{product.type}</p> </span>
                                    <span className='info-line'><p className='line-label'>Inventory:</p> <p className='line-data'>{product.inventory}</p> </span>
                                    <span className='info-line'><p className='line-label'>Volume:</p> <p className='line-data'>{product.volume}</p> </span>
                                    <span className='info-line'><p className='line-label'>Variation:</p> <p className='line-data'>{product.variation}</p> </span>
                            
                            </div>
                            <div className='right'>
                                    <span className='info-line line-down'><p className='line-label'>Description:</p> <p className='line-data'>{product.description}</p> </span>
                                    <span className='info-line line-down'><p className='line-label'>Materials Used:</p> <p className='line-data'>{product.material}</p> </span>
                                    <span className='info-line line-down'><p className='line-label'>SEO Keywords:</p> <p className='line-data'>{product.tags}</p> </span>
                            
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <ConfirmationDialog
                    onCancel={hideConfirmationDialog}
                    onConfirm={handleDelete}
                />
            )}
        </div>
    );
}

export default ViewProduct;
