import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MapComponent from "./Components/Map";

import Signup from "./Components/Map/Signup";
import Signin from "./Components/Map/Signin";
import Searchbar from "./Components/Map/Searchbar";
import Login from "./Components/Map/Login";
import GoogleForm from "./Components/Map/GoogleForm";
import Dashboard from "./dashboard/Dashboard";
import axios from "axios";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import Buyer from "./Components/Map/Buyer";





axios.defaults.baseURL = "http://34.207.195.74:5000/";

// axios.defaults.withCredentials = true;
function App() {
  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <Routes>
          <Route index element={<MapComponent />} />

          <Route path="/signin" element={<Signin />} />
          <Route path="/searchbar" element={<Searchbar />} />
          <Route path="/login" element={<Login />} />
          <Route path="/googleform" element={<GoogleForm />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/productdetail/:productId" element={<ProductDetail />} />
          <Route path="/buyer" element={<Buyer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
