import React, { useState } from "react";
import sign from "../icons/sign.png";
import { useNavigate, Link } from "react-router-dom"; // Import useHistory
import "./Signup.css";
import GoogleForm from "./GoogleForm";

const Signup = () => {
  const navigate = useNavigate();

  const [notification, setNotification] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    isSeller: false, // Set default value for isSeller
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSignup = (event) => {
    event.preventDefault();

    if (!validateFields()) {
      setNotification("Please fill in all required fields.");
      return;
    }

    // Navigate to the signin route
    navigate("/signin");
  };

  const validateFields = () => {
    const { name, email, password } = formData;
    if (!name || !email || !password) {
      // If any of the required fields are empty, return false
      return false;
    }

    // Additional validation logic can be added here if needed

    return true; // All fields are filled in
  };
  // const handlePagecontainer = () => {
  //   navigate("/");
  // };
  return (
    <div className="page-container">
      <div className="page-section">
        <div className="signup-section">
          <div>
            <img src={sign} alt="signup" className="signup-image" />
          </div>
          <div>
            <h2>Map-Driven Product Discovery</h2>
          </div>
        </div>
        <div className="signup-form-section">
          <div className="signup-form-title">
            <h4>GFOKK</h4>
          </div>
          <div className="get-started">
            <h2>Get Started</h2>
          </div>
          <p className="signup-form-para">
            Already have an account?<a href="/signin">Sign In</a>
          </p>
          <form onSubmit={handleSignup}>
            {/* Form fields */}
            <div className="signup-form-label">
              <input
                type="text"
                placeholder="Name"
                id="nameInput"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                placeholder="Email"
                id="emailInput"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="password"
                placeholder="Password"
                id="passwordInput"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="seller-checkbox">
              <label htmlFor="isSeller" className="seller-checkbox">
                <span className="signup-form-para1">
                  Check this if you are a seller.
                </span>
                <input
                  type="checkbox"
                  id="isSeller"
                  name="isSeller"
                  checked={formData.isSeller}
                  onChange={handleChange}
                />
              </label>
            </div>
            {notification && <div className="notification">{notification}</div>}
            <div className="signup-button">
              <button type="submit">Signup</button>

              <p className="separator">OR</p>
              <GoogleForm />

              <p className="signup-para">
                By Signing up, I agree to the
                <span className="underline-text">Terms of Service</span> and
                <span className="underline-text">Privacy Policy</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;

// import React, { useState } from "react";
// import signup1 from "../icons/signup1.png";
// import { useNavigate } from "react-router-dom"; // Import useHistory
// import axios from "axios"; // Import axios for making HTTP requests
// import "./Signup.css";
// import GoogleForm from "./GoogleForm";

// const Signup = () => {
//   const navigate = useNavigate();

//   const [notification, setNotification] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     isSeller: false, // Set default value for isSeller
//   });

//   const handleChange = (event) => {
//     const { name, value, type, checked } = event.target;
//     const newValue = type === "checkbox" ? checked : value;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: newValue,
//     }));
//   };

//   const handleSignup = async (event) => {
//     event.preventDefault();

//     if (!validateFields()) {
//       setNotification("Please fill in all required fields.");
//       return;
//     }

//     try {
//       // Send the form data to the backend
//       const response = await axios.post("/api/v1/auth/signup", formData);
//       console.log("Signup successful:", response.data);

//       // Navigate to the signin route upon successful signup
//       navigate("/signin");
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotification(
//         error.response.data.message ||
//           "An error occurred while signing up. Please try again."
//       );
//     }
//   };

//   const validateFields = () => {
//     const { name, email, password } = formData;
//     if (!name || !email || !password) {
//       // If any of the required fields are empty, return false
//       return false;
//     }

//     // Additional validation logic can be added here if needed

//     return true; // All fields are filled in
//   };

//   return (
//     <div className="page-container">
//       <div className="page-section">
//         <div className="signup-section">
//           <img src={signup1} alt="signup" className="signup-image" />
//         </div>
//         <div className="signup-form-section">
//           <form onSubmit={handleSignup}>
//             {/* Form fields */}
//             <div className="signup-form-label">
//               <input
//                 type="text"
//                 placeholder="Name"
//                 id="nameInput"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//               />
//               <input
//                 type="email"
//                 placeholder="Email"
//                 id="emailInput"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//               <input
//                 type="password"
//                 placeholder="Password"
//                 id="passwordInput"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="seller-checkbox">
//               <label htmlFor="isSeller" className="seller-checkbox">
//                 <span className="signup-form-para1">
//                   Check this if you are a seller.
//                 </span>
//                 <input
//                   type="checkbox"
//                   id="isSeller"
//                   name="isSeller"
//                   checked={formData.isSeller}
//                   onChange={handleChange}
//                 />
//               </label>
//             </div>
//             {notification && <div className="notification">{notification}</div>}
//             <div className="signup-button">
//               <button type="submit">Signup</button>
//               <p className="separator">OR</p>

//               <GoogleForm />

//               <p className="signup-para">
//                 By Signing up, I agree to the
//                 <span className="underline-text">Terms of Service</span> and
//                 <span className="underline-text">Privacy Policy</span>
//               </p>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;
